<template>
  <div class="bg-avarto py-24 text-center px-2">
    <h2 class="text-white text-2xl text-bold">Jetzt kostenlos registrieren</h2>
    <p class="text-white">
      Melden Sie sich jetzt kostenlos zu dem IT Security Roundtable von Arvato
      Systems an.
    </p>
    <button
      class="px-4 py-2 bg-white rounded-full mt-6"
      @click="$router.push('/register')"
    >
      Jetzt registrieren
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
