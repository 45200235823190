<template>
  <div class="bg-cover bg-hero-pattern">
    <button
      @click="$router.push('/login')"
      class="
        absolute
        right-0
        px-4
        py-2
        mt-12
        mr-12
        bg-white
        rounded-full
        opacity-90
      "
    >
      <div class="opacity-100">Jetzt einloggen</div>
    </button>
    <div class="py-52">
      <!-- <div class="mx-auto">
        <img
          src="https://cdn.enra.app/avarto/Logo.png"
        />
      </div> -->
      <div class="my-auto px-2 text-center text-white">
        <img
          class="w-56 mb-12 mx-auto"
          src="https://cdn.enra.app/avarto/Logo.png"
        />
        <h1 class="text-5xl">IT Security Roundtable</h1>
        <p class="mt-2 text-xl">30. September 2021 I 12:45 Uhr bis 17:30 Uhr</p>
      </div>
    </div>
  </div>
  <div class="w-9/12 mx-auto">
    <h2 class="mt-24 text-3xl">IT Security Roundtable von Arvato Systems</h2>
    <p class="mt-4">
      Durch Interviews mit rund 50 IT Security Experten aus verschiedenen
      Branchen haben wir einen Überblick darüber erhalten, welche
      Fragestellungen und Tools aus dem IT Security Kosmos für Unternehmen
      aktuell von besonderer Relevanz sind. All diese Themen werden im Rahmen
      des IT Security Roundtables behandelt und von Fachexperten aufgegriffen -
      von Cloud Security über Vulnerability Management Tools bis hin zu
      präventiven Maßnahmen zum Schutz vor Ransomware.
    </p>
    <p class="mt-4">
      Die Agenda ist thematisch in zwei Stränge unterteilt: Zum einen gibt es
      Sessions über strategische Fragestellungen, zum anderen über konkrete
      Technologien & Lösungen. Je nach Ihrer Interessenslage können Sie
      entscheiden, woran Sie teilnehmen möchten. Es bleibt in jeder Session Raum
      für Fragen (45 Minuten Vortrag und 10 Minuten Fragen und Antworten).
    </p>
    <p class="mt-4">
      Nutzen Sie die Chance, mit unseren IT Security Spezialisten in Interaktion
      zu treten.
    </p>
    <p class="mt-4">Wir freuen uns auf Sie und spannende Diskussionen!</p>
  </div>
  <div class="mt-24">
    <CTA />
  </div>
  <div class="bg-cover py-48 px-2 bg-agenda">
    <div class="text-center">
      <h1 class="text-4xl text-white">Agenda</h1>
      <p class="mt-2 text-xl text-white">
        Hier finden Sie alle Details rund um das Programm des ersten IT Security
        Roundtables
      </p>
    </div>
  </div>

  <div class="w-9/12 mx-auto">
    <h2 class="text-2xl mt-24 mb-6">Die Agenda</h2>
    <div class="bg-gray-400">
      <table class="mb-12">
        <tr>
          <td class="pr-6 py-4 text-sm font-medium text-gray-900">
            <img class="w-8 blue" src="./../assets/help.svg" />
          </td>
          <td class="pr-6 py-4 text-sm font-medium text-gray-900">
            Strang strategische Themen
          </td>
        </tr>
        <tr>
          <td class="pr-6 py-4 text-sm font-medium text-gray-900">
            <img class="w-8 blue" src="./../assets/tactic.svg" />
          </td>
          <td class="pr-6 py-4 text-sm font-medium text-gray-900">
            Strang konkrete Technologien/ Lösungen
          </td>
        </tr>
      </table>
    </div>

    <AgendaTable :agenda="fullAgenda" />
  </div>
  <div class="mt-24">
    <CTA />
  </div>
</template>

<script>
import AgendaTable from "./../components/AgendaTable.vue";
import CTA from "./../components/CTA.vue";
export default {
  name: "Home",
  components: {
    AgendaTable,
    CTA,
  },
  methods: {
    toggleAgenda() {
      this.technical = !this.technical;
      this.nonTechnical = !this.nonTechnical;
    },
  },
  data() {
    return {
      nonTechnical: true,
      technical: false,
      fullAgenda: [
        {
          start: "12:45 Uhr",
          ende: "13:00 Uhr",
          dauer: "15",
          note: "Registrierung/ Sign in",
        },
        {
          start: "13:00 Uhr",
          ende: "13:15 Uhr",
          dauer: "15",
          note: "Moderation - Begrüßung - Einleitung - 'Cyber Security als Business Process' - 2 Fragen",
          speaker: "Antonia Krieg, Andreas Nolte",
          role: "Senior Marketing Consultant - Arvato Systems GmbH, Head of Cyber Security - Arvato Systems GmbH",
        },
        {
          type: "strategy",
          start: "13:20 Uhr",
          ende: "14:15 Uhr",
          dauer: "55",
          note: "Security 'Modern/hybrid workplace' durch die Workplace Enterprise Suite - allgemeine Einführung, mit Fokus auf die Mehrwerte für die IT Security",
          speaker: "Markus Holtkamp, Rainer Langhorst",
          role: "Director Workplace und O365 Services - Arvato Systems GmbH, Security und Compliance Consultant",
        },
        {
          type: "technical",
          start: "13:20 Uhr",
          ende: "14:15 Uhr",
          dauer: "55",
          note: "Cyber Security - So bestimmen Sie die Prioritäten zur Verbesserung der Sicherheitslage: moderne Angriffe umgehen zunehmend Sicherheitsmaßnahmen, da Sicherheitslösungen True- und False-Positive-Raten abwägen müssen. Darüber hinaus kann eine auf Compliance basierende Vorbereitung die Wirksamkeit einschränken - deshalb hier nun praktische Aspekte, um zu bestimmen, wie Sie zu einem vernünftigen Setup gelangen, das Compliance-Anforderungen und ein effektives Sicherheits-Setup in Einklang bringt.",
          speaker: "Dr. Andreas Rohr",
          role: "CTO Deutsche Cyber Sicherheitsorganisation GmbH",
        },
        {
          start: "14:15 Uhr",
          ende: "14:20 Uhr",
          dauer: "5",
          note: "Überleitung",
        },
        {
          type: "strategy",
          start: "14:20 Uhr",
          ende: "15:15 Uhr",
          dauer: "55",
          note: "Cyber Security -  aus CISO Sicht: Erfolgreiches Risikomanagement in der Informationssicherheit durch umfassendes Stakeholdermanagement und der Nutzung relevanter Kennzahlen.",
          speaker: "Roger Schranz",
          role: "Head of Information Security",
        },
        {
          type: "technical",
          start: "14:20 Uhr",
          ende: "15:15 Uhr",
          dauer: "55",
          note: "Cyber Security - die wirksamsten Tools: Entwicklung eines abgestimmten Services Portfolios für Ihr Unternehmen oder “Was ist Cyber Security? Ein Erklärungsversuch.“",
          speaker: "Timo Schlüter",
          role: "Business Consultant Cyber Security, Arvato Systems GmbH",
        },
        { start: "15:15 Uhr", ende: "15:30 Uhr", dauer: "15", note: "Pause" },
        {
          type: "strategy",
          start: "15:30 Uhr",
          ende: "16:25 Uhr",
          dauer: "55",
          note: "Cyber Security – (Managed) SIEM & Managed Detection and Response – die Basis einer strukturierten Gefahrenabwehr.",
          speaker: "Dr. Martin Ussath",
          role: "Head of Managed Detection and Response, Arvato Systems GmbH",
        },
        {
          type: "technical",
          start: "15:30 Uhr",
          ende: "16:25 Uhr",
          dauer: "55",
          note: "House of „Cloud Security“ - Effektiver Schutz & IT Security in Azure, AWS, Google Cloud - Was bieten die Public Cloud Anbieter im Bereich IT Security allgemein, Schwerpunkt Cyber Security und SOC Services wie SIEM, MDR, EDR, ….",
          speaker: "Michiel Dobber, Jannis Rhode, Janis Scholz, Sebastian Rieck",
          role: "Consultants und Spezialisten in verschiedenen Bereichen der Arvato Systems GmbH",
        },
        {
          start: "16:25 Uhr",
          ende: "16:30 Uhr",
          dauer: "5",
          note: "Überleitung",
        },
        {
          type: "strategy",
          start: "16:30 Uhr",
          ende: "17:25 Uhr",
          dauer: "55",
          note: "„Schwachstellen Management mit Vader“: Wie es gelingt repetitive Fleißarbeit zu ersparen, Daten automatisiert managebar zu machen und den Schwachstellenmanagement-Prozess im Unternehmen zu verschlanken.",
          speaker: "Christina Finck",
          role: "Information Security Consultant, Arvato Systems North America",
        },
        {
          type: "technical",
          start: "16:30 Uhr",
          ende: "17:25 Uhr",
          dauer: "55",
          note: "IT Security - Ransomware - Wie geht man vor und welche Präventivmaßnahmen sind wirkungsvoll?",
          speaker: "Frank Westheider",
          role: "Head of Security Operations, Arvato Systems GmbH",
        },
        {
          start: "17:25 Uhr",
          ende: "17:30 Uhr",
          dauer: "5",
          note: "Verabschiedung",
          speaker: "Antonia Krieg, Andreas Nolte",
          role: "Senior Marketing Consultant - Arvato Systems GmbH, Head of Cyber Security - Arvato Systems GmbH",
        },
      ],
      nonTechnicalAgenda: [
        {
          ende: "13:00 Uhr",
          dauer: "15",
          note: "Registrierung/ Sign in",
        },
        {
          ende: "13:15 Uhr",
          dauer: "15",
          note: "Moderation - Begrüßung - Einleitung - 'Cyber Security als Business Process' - 2 Fragen",
        },
        { ende: "13:20 Uhr", dauer: "5", note: "Teilung" },
        {
          ende: "14:15 Uhr",
          dauer: "45+10",
          note: "Security 'Modern/hybrid workplace' durch die Workplace Enterprise Suite - allgemeine Einführung, mit Fokus auf die Mehrwerte für die IT Security",
          speaker: "Markus Holtkamp, Rainer Langhorst",
          role: "Director Workplace und O365 Services - Arvato Systems GmbH, Security und Compliance Consultant",
        },
        {
          ende: "15:15 Uhr",
          dauer: "45+10",
          note: "Cyber Security -  aus CISO Sicht: Erfolgreiches Risikomanagement in der Informationssicherheit durch umfassendes Stakeholdermanagement und der Nutzung relevanter Kennzahlen.",
          speaker: "Roger Schranz",
          role: "Head of Information Security",
        },
        { ende: "15:30 Uhr", dauer: "15", note: "Pause" },
        {
          ende: "16:25 Uhr",
          dauer: "45+10",
          note: "Cyber Security – (Managed) SIEM & Managed Detection and Response – die Basis einer strukturierten Gefahrenabwehr.",
          speaker: "Dr. Martin Ussath",
          role: "Head of Managed Detection and Response, Arvato Systems GmbH",
        },
        {
          ende: "17.25 Uhr",
          dauer: "45+10",
          note: "IT Security - Schwachstellen Management mit Vader®",
          speaker: "Christina Finck",
          role: "Senior Projekt Manager, Arvato Systems GmbH",
        },
        {
          ende: "17:30 Uhr",
          dauer: "5",
          note: "Verabschiedung",
          speaker: "Antonia Krieg, Andreas Nolte",
        },
      ],
      agenda: [
        {
          ende: "13:00 Uhr",
          dauer: "15",
          note: "Registrierung/ Sign in",
        },
        {
          ende: "13:15 Uhr",
          dauer: "15",
          note: "Moderation - Begrüßung - Einleitung - 'Cyber Security als Business Process' - 2 Fragen",
        },
        { ende: "13:20 Uhr", dauer: "5", note: "Teilung" },
        {
          ende: "14:15 Uhr",
          dauer: "45+10",
          note: "Cyber Security - So bestimmen Sie die Prioritäten zur Verbesserung der Sicherheitslage: moderne Angriffe umgehen zunehmend Sicherheitsmaßnahmen, da Sicherheitslösungen True- und False-Positive-Raten abwägen müssen. Darüber hinaus kann eine auf Compliance basierende Vorbereitung die Wirksamkeit einschränken - deshalb hier nun praktische Aspekte, um zu bestimmen, wie Sie zu einem vernünftigen Setup gelangen, das Compliance-Anforderungen und ein effektives Sicherheits-Setup in Einklang bringt.",
          speaker: "Dr. Andreas Rohr",
          role: "CTO Deutsche Cyber Sicherheitsorganisation GmbH",
        },
        {
          ende: "15:15 Uhr",
          dauer: "45+10",
          note: "Cyber Security - die wirksamsten Tools: Entwicklung eines abgestimmten Services Portfolios für Ihr Unternehmen oder “Was ist Cyber Security? Ein Erklärungsversuch.“",
          speaker: "Timo Schlüter",
          role: "Business Consultant Cyber Security, Arvato Systems GmbH",
        },
        { ende: "15:30 Uhr", dauer: "15", note: "Pause" },
        {
          ende: "16:25 Uhr",
          dauer: "45+10",
          note: "Cloud Security - Azure: Effektiver Schutz sensibler Daten - Azure 'Bordmittel' und Architektur, 'Secure inside'",
          speaker: "",
          role: "Microsoft",
        },
        {
          ende: "16:25 Uhr",
          dauer: "45+10",
          note: "Cloud Security - AWS: Effektiver Schutz sensibler Daten - AWS 'Bordmittel' und Architektur",
          speaker: "",
          role: "AWS",
        },
        {
          ende: "16:25 Uhr",
          dauer: "45+10",
          note: "Cloud Security - Google: Effektiver Schutz sensibler Daten - Google 'Bordmittel' und Architektur",
          speaker: "Markus  Klein",
          role: "Global Head Cyber Security, Google Inc.",
        },
        {
          ende: "17.25 Uhr",
          dauer: "45+10",
          note: "IT Security - Ransomware - Wie geht man vor und welche Präventivmaßnahmen sind wirkungsvoll?",
          speaker: "Frank Westheider",
          role: "Head of Security Operations, Arvato Systems GmbH",
        },
        {
          ende: "17:30 Uhr",
          dauer: "5",
          note: "Verabschiedung",
          speaker: "Antonia Krieg, Andreas Nolte",
        },
      ],
    };
  },
};
</script>
